import AccreditationsImport from "pages/Import/AccreditationsImport"
import AreasImport from "pages/Import/AreasImport"
import NotesImport from "pages/Import/NotesImport"
import LanguagesImport from "pages/Import/LanguagesImport"
import CreateTutorial from "pages/Tutorials/CreateTutorial"
import React from "react"
import { Redirect } from "react-router-dom"
import DirectorsCommitteesImport from "pages/Import/DirectorsCommitteesImport"

// Profile
const UserProfile = React.lazy(() => import("../pages/Authentication/user-profile"))

// Authentication related pages
const Login = React.lazy(() => import("../pages/Authentication/Login"))
const Logout = React.lazy(() => import("../pages/Authentication/Logout"))

// Account
const Account = React.lazy(() => import("../pages/Account/index"))
const AccountConfig = React.lazy(() => import("../pages/Account/config"))
const AccountTokens = React.lazy(() => import("../pages/Account/tokens"))

// User
const User = React.lazy(() => import("../pages/User/index"))

// Accreditations
const Accreditation = React.lazy(() => import("../pages/Accreditation/index"))

// Titles
const Title = React.lazy(() => import("../pages/Title/index"))

// Languages
const Language = React.lazy(() => import("../pages/Language/index"))

// Specialties
const Specialty = React.lazy(() => import("../pages/Specialty/index"))

// Dashboard
const Dashboard = React.lazy(() => import("../pages/Dashboard/index"))

// Sales
const Sales = React.lazy(() => import("../pages/Sales/index"))
const CompanyInfo = React.lazy(() => import("../pages/Sales/CompanyInfo"))
const Tutorials = React.lazy(() => import("../pages/Tutorials"))
const TutorialsCategory = React.lazy(() => import("../pages/Tutorials/TutorialsCategory"))
const TutorialDetails = React.lazy(() => import("../pages/Tutorials/TutorialDetails"))

// DB Updations
const DBUpdations = React.lazy(() =>
  import("../pages/DbUpdation/updations-list")
)
const DBCreateUpdation = React.lazy(() =>
  import("../pages/DbUpdation/create-updation")
)

// Dataflow
const Dataset = React.lazy(() => import("../pages/Dataflow/dataset"))
const Source = React.lazy(() => import("../pages/Dataflow/source"))
const SourceData = React.lazy(() => import("../pages/Dataflow/SourceData"))
const DatasetData = React.lazy(() => import("../pages/Dataflow/DatasetData"))
const WebhookConfig = React.lazy(() =>
  import("../pages/Dataflow/webhookConfig")
)

const ComingSoon = React.lazy(() => import("../pages/Utility/pages-comingsoon"))

const SocialPost = React.lazy(() => import("../pages/SocialPost/index"))
const Website = React.lazy(() => import("../pages/Website/index"))
const WebsiteSystemPages = React.lazy(() =>
  import("../pages/Website/system-pages")
)
const WebsiteThemeAdmin = React.lazy(() => import("../pages/Website/theme-admin"))
const WebsiteThemes = React.lazy(() => import("../pages/Website/themes"))

const Setting = React.lazy(() => import("../pages/Setting/index"))

// Imports
const ContactsImportV1 = React.lazy(() =>
  import("../pages/Import/ContactsImportV1")
)

const ContactsImportV2 = React.lazy(() =>
  import("../pages/Import/ContactsImportV2")
)

const EventsImport = React.lazy(() => import("../pages/Import/EventsImport"))
const BillableItemsImport = React.lazy(() => import("../pages/Import/BillableItems"))
const CreditCardsSGImport = React.lazy(() => import("../pages/Import/CreditCardsSG"))
const AutoRenewSGImport = React.lazy(() => import("../pages/Import/AutoRenewSG"))
const BioImport = React.lazy(() => import("../pages/Import/BioImport"))
const BrokerageRolesImport = React.lazy(() => import("../pages/Import/BrokerageRolesImport"))

const MasterUser = React.lazy(() => import("../pages/MasterUser/index"))

// Miscellaneous
const PostGiveAway = React.lazy(() => import("../pages/PostGiveAway/index"))
const RaffleItem = React.lazy(() => import("../pages/RaffleItem/index"))

// Agenbook webinars
const Webinars = React.lazy(() => import("../pages/Agentbook/webinars/index"))

// Agenbook sponsers
const Sponsers = React.lazy(() => import("../pages/Agentbook/sponsers/index"))

const CampaignsList = React.lazy(() => import("../pages/Campaigns/List/index"))
const CampaignsOverview = React.lazy(() => import("../pages/Campaigns/Overview/index"))
const CampaignsAccounts = React.lazy(() => import("../pages/Campaigns/Accounts"))

// Map 
const Map = React.lazy(() => import("../pages/Map/index"))

// Sendgrid 
const SendgridSuppressions = React.lazy(() => import("../pages/Sendgrid/Suppressions/index"))

const ProfileActiveItems = React.lazy(() => import("../pages/Account/ProfileActiveItems"))

const AssociationsLicenses = React.lazy(() => import("../pages/Associations/Licenses"))
const M1Integration = React.lazy(() => import("../pages/Associations/m1_integration"))
const PaymentGateways = React.lazy(() => import("../pages/Associations/PaymentGateways"))

const TeamMembers = React.lazy(() => import("../pages/TeamMembers/index"))

// Listings
const ListingsFeeds = React.lazy(() => import("../pages/Listings/feeds"))

// Application Defaults
const SharedMediaLibrary = React.lazy(() => import("../pages/Application/shared-media-library"))

// Quickbooks
const QBEntities = React.lazy(() => import("../pages/QB/index"))

// AgentBook SSO
const AgentbookSSO = React.lazy(() => import("../pages/Agentbook/sso"))
const ListingsIndexes = React.lazy(() => import("../pages/Listings/indexes"))

const M1 = React.lazy(() => import("../pages/M1"))

const M1Sync = React.lazy(() => import("../pages/Import/M1Sync"))

const AbEventsImport = React.lazy(() => import("../pages/Import/AbEventsImport"))

const IntegrationsTypes = React.lazy(() => import("../pages/Integrations/types"))
const IntegrationsAccounts = React.lazy(() => import("../pages/Integrations/accounts"))
const IntegrationsViews = React.lazy(() => import("../pages/Integrations/views"))
const IntegrationsInbound = React.lazy(() => import("../pages/Integrations/inbound"))

const authProtectedRoutes = [
  { path: "/sales", component: Sales },
  { path: "/sales/company/:companyID", component: CompanyInfo },
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },
  { path: "/users", component: User },

  { path: "/team-members", component: TeamMembers },

  { path: "/accounts", component: Account },
  { path: "/accounts/config/:accountID?", component: AccountConfig },
  { path: "/accounts/active-recurring-count", component: ProfileActiveItems },
  { path: "/accounts/tokens/:accountID?", component: AccountTokens },

  { path: "/accreditations", component: Accreditation },
  { path: "/titles", component: Title },
  { path: "/languages", component: Language },
  { path: "/specialties", component: Specialty },

  { path: "/db/updations", component: DBUpdations },
  { path: "/db/updation/create", component: DBCreateUpdation },

  { path: "/df/datasets", component: Dataset },
  { path: "/df/dataset-data/:datasetID", component: DatasetData },
  { path: "/df/sources/:datasetID?", component: Source },
  { path: "/df/source-data/:sourceID", component: SourceData },
  { path: "/df/webhook-config/:sourceID", component: WebhookConfig },

  { path: "/social-post", component: SocialPost },
  { path: "/settings", component: Setting },

  { path: "/websites", component: Website },
  { path: "/websites/system-pages", component: WebsiteSystemPages },
  { path: "/websites/theme-admin", component: WebsiteThemeAdmin },
  { path: "/websites/themes", component: WebsiteThemes },

  { path: "/import/contacts/v1", component: ContactsImportV1 },
  { path: "/import/contacts/v2", component: ContactsImportV2 },
  { path: "/import/events", component: EventsImport },
  { path: "/import/billable-items", component: BillableItemsImport },
  { path: "/import/cc-tokens", component: CreditCardsSGImport },
  { path: "/import/auto-renew", component: AutoRenewSGImport },
  { path: "/import/bio", component: BioImport },
  { path: "/import/languages", component: LanguagesImport },
  { path: "/import/accreditations", component: AccreditationsImport },
  { path: "/import/areas", component: AreasImport },
  { path: "/import/notes", component: NotesImport },
  { path: "/import/brokerage-roles", component: BrokerageRolesImport },
  { path: "/import/ab-events", component: AbEventsImport },
  { path: "/import/directors-committees", component: DirectorsCommitteesImport },

  { path: "/master-users", component: MasterUser },

  { path: "/post-give-aways", component: PostGiveAway },
  { path: "/raffle-items", component: RaffleItem },

  { path: "/agentbook/webinars", component: Webinars },
  { path: "/agentbook/sponsers", component: Sponsers },

  { path: "/campaigns", component: CampaignsList },
  { path: "/campaigns/accounts", component: CampaignsAccounts },
  { path: "/campaigns/overview", component: CampaignsOverview },

  { path: "/map", component: Map },
  { path: "/sendgrid/suppressions", component: SendgridSuppressions },

  { path: "/associations/licenses", component: AssociationsLicenses },
  { path: "/associations/m1-integration", component: M1Integration },
  { path: "/associations/payment-gateways", component: PaymentGateways },

  { path: "/tutorials", component: Tutorials },
  { path: "/tutorials/create", component: CreateTutorial },
  { path: "/tutorials/edit/:tutorialID", component: CreateTutorial },
  { path: "/tutorials/:categoryID", component: TutorialsCategory },
  { path: "/tutorials/view/:tutorialID", component: TutorialDetails },

  { path: "/listings/feeds", component: ListingsFeeds },
  { path: "/listings/indexes", component: ListingsIndexes },

  { path: "/application/shared-media-library", component: SharedMediaLibrary },

  { path: "/qb-entities", component: QBEntities },

  { path: "/m1", component: M1 },

  { path: "/agentbook/sso", component: AgentbookSSO },

  { path: "/import/m1-sync", component: M1Sync },

  { path: "/integrations/types", component: IntegrationsTypes },
  { path: "/integrations/accounts", component: IntegrationsAccounts },
  { path: "/integrations/views", component: IntegrationsViews },
  { path: "/integrations/inbound", component: IntegrationsInbound },

  // this route should be at the end of all other routes  
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
