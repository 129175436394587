import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef();
  const authState = useSelector(state => state.Login?.user);
  const isAdmin = authState?.user?.id === 1;
  const userPermissions = authState?.user?.permissions || {}

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              (isAdmin || userPermissions.sales_view) && (
                <li>
                  <Link to="/sales">
                    <i className="bx bx-briefcase-alt-2"></i>
                    <span>{props.t("Sales")}</span>
                  </Link>
                </li>
              )
            }

            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {
              (isAdmin || userPermissions.team_members_view) && (
                <li>
                  <Link to="/team-members">
                    <i className="mdi mdi-account-group"></i>
                    <span>{props.t("Team Members")}</span>
                  </Link>
                </li>
              )
            }

            {
              (isAdmin || userPermissions.master_users_view) && (
                <li>
                  <Link to="/master-users">
                    <i className="bx bxs-user-badge"></i>
                    <span>{props.t("Master Users")}</span>
                  </Link>
                </li>
              )
            }

            {
              (isAdmin || userPermissions.accounts_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-building"></i>
                    <span>{props.t("Accounts")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/accounts">
                        <i className="bx bx-building"></i>
                        <span>{props.t("Accounts")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/users">
                        <i className="bx bx-user"></i>
                        <span>{props.t("Users")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/accounts/active-recurring-count">
                        <i className="bx bx-sync"></i>
                        <span>{props.t("Active Recurring")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/accounts/tokens">
                        <i className="bx bx-key"></i>
                        <span>{props.t("Tokens")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/qb-entities">
                        <i className="bx bxs-book"></i>
                        <span>{props.t("QuickBooks")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            }

            {
              (isAdmin || userPermissions.associations_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-group"></i>
                    <span>{props.t("Associations")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/associations/payment-gateways">
                        <i className="bx bx-credit-card"></i>
                        <span>{props.t("Payment Gateways")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/associations/licenses">
                        <i className="bx bx-id-card"></i>
                        <span>{props.t("Licenses")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/associations/m1-integration">
                        <i className="bx bx-plug"></i>
                        <span>{props.t("M1 Integration")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            }

            {
              (isAdmin || userPermissions.imports_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-import"></i>
                    <span>{props.t("Imports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {/* <li>
                  <Link to="/import/contacts/v1">
                    <i className="bx bx-user"></i>
                    <span>{props.t("Import Contacts V1")}</span>
                  </Link>
                </li> */}
                    <li>
                      <Link to="/import/contacts/v2">
                        <i className="bx bx-user"></i>
                        <span>{props.t("Contacts")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/events">
                        <i className="bx bx-calendar-event"></i>
                        <span>{props.t("Dataflow Events")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/billable-items">
                        <i className="bx bx-money"></i>
                        <span>{props.t("Billable Items")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/cc-tokens">
                        <i className="bx bx-credit-card"></i>
                        <span>{props.t("Credit Card Tokens")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/auto-renew">
                        <i className="bx bx-sync"></i>
                        <span>{props.t("Auto Renew List")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/bio">
                        <i className="bx bx-news"></i>
                        <span>{props.t("Bio Import")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/languages">
                        <i className="mdi mdi-google-translate"></i>
                        <span>{props.t("Languages Import")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/accreditations">
                        <i className="bx bx-check-shield"></i>
                        <span>{props.t("Accreditations Import")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/areas">
                        <i className="bx bx-map"></i>
                        <span>{props.t("Areas Import")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/notes">
                        <i className="bx bx-note"></i>
                        <span>{props.t("Notes Import")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/brokerage-roles">
                        <i className="bx bxs-user-badge"></i>
                        <span>{props.t("Brokerage Roles")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/m1-sync">
                        <i className="bx bx-plug"></i>
                        <span>{props.t("M1 Sync Status")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/ab-events">
                        <i className="bx bx-calendar-event"></i>
                        <span>{props.t("AB Events")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/import/directors-committees">
                        <i className="mdi mdi-account-group"></i>
                        <span>{props.t("Directors & Committees")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            }

            {
              (isAdmin) && (
                <li>
                  <Link to="/m1">
                    <i className="bx bx-plug"></i>
                    <span>{props.t("M1")}</span>
                  </Link>
                </li>
              )
            }

            {/* {
              (isAdmin || userPermissions.mls_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-globe"></i>
                    <span>{props.t("MLS")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/mls/accounts-list">
                        <i className="bx bx-building"></i>
                        <span>{props.t("Accounts List")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/mls/create-view">
                        <i className="bx bx-table"></i>
                        <span>{props.t("Create View")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/mls/view-users">
                        <i className="bx bx-data"></i>
                        <span>{props.t("View DB Users")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            } */}

            {
              (isAdmin || userPermissions.mls_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-globe"></i>
                    <span>{props.t("Integrations")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/integrations/accounts">
                        <i className="bx bx-building"></i>
                        <span>{props.t("Accounts")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/integrations/views">
                        <i className="bx bx-table"></i>
                        <span>{props.t("Views")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/integrations/inbound">
                        <i className="bx bxs-inbox"></i>
                        <span>{props.t("Inbound")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/integrations/types">
                        <i className="bx bx-list-ul"></i>
                        <span>{props.t("Types")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )
            }

            {
              (isAdmin || userPermissions.accreditations_view || userPermissions.titles_view || userPermissions.languages_view || userPermissions.specialties_view) && (
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-slider-alt"></i>
                    <span>{props.t("Miscellaneous")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {(isAdmin || userPermissions.accreditations_view) && (
                      <li>
                        <Link to="/accreditations">
                          <i className="bx bx-check-shield"></i>
                          <span>{props.t("Accreditations")}</span>
                        </Link>
                      </li>
                    )}
                    {(isAdmin || userPermissions.titles_view) && (
                      <li>
                        <Link to="/titles">
                          <i className="bx bx-badge-check"></i>
                          <span>{props.t("Titles")}</span>
                        </Link>
                      </li>
                    )}
                    {(isAdmin || userPermissions.languages_view) && (
                      <li>
                        <Link to="/languages">
                          <i className="bx bx-globe-alt"></i>
                          <span>{props.t("Languages")}</span>
                        </Link>
                      </li>
                    )}
                    {(isAdmin || userPermissions.specialties_view) && (
                      <li>
                        <Link to="/specialties">
                          <i className="bx bxs-bookmark-star"></i>
                          <span>{props.t("Specialties")}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )
            }

            {(isAdmin || userPermissions.db_config_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-data"></i>
                  <span>{props.t("DB Config")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/db/updations">{props.t("Updations")}</Link>
                  </li>
                  <li>
                    <Link to="/db/updation/create">
                      {props.t("Create Updation")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.social_post_view) && (
              <li>
                <Link to="/social-post">
                  <i className="bx bxl-facebook"></i>
                  <span>{props.t("Social Post")}</span>
                </Link>
              </li>
            )}

            {(isAdmin || userPermissions.dataflow_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-stats"></i>
                  <span>{props.t("Dataflow")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/df/datasets">{props.t("Datasets")}</Link>
                  </li>
                  <li>
                    <Link to="/df/sources">{props.t("Sources")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {/* <li>
              <Link to="/websites">
                <i className="bx bx-globe"></i>
                <span>{props.t("Websites")}</span>
              </Link>
            </li> */}

            {(isAdmin || userPermissions.websites_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-globe"></i>
                  <span>{props.t("Websites")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/websites">{props.t("List")}</Link>
                  </li>
                  <li>
                    <Link to="/websites/system-pages">
                      {props.t("System Pages")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/websites/theme-admin">
                      {/* <i className="bx bx-table"></i> */}
                      <span>{props.t("Theme Admin")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/websites/themes">
                      {props.t("Themes")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.post_give_aways_view || userPermissions.raffle_items_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-slider-alt"></i>
                  <span>{props.t("Misc")}</span>
                </Link>
                <ul className="sub-menu">
                  {(isAdmin || userPermissions.post_give_aways_view) && (
                    <li>
                      <Link to="/post-give-aways">
                        <i className="bx bx-gift"></i>
                        <span>{props.t("Post Give Aways")}</span>
                      </Link>
                    </li>
                  )}
                  {(isAdmin || userPermissions.raffle_items_view) && (
                    <li>
                      <Link to="/raffle-items">
                        <i className="bx bx-gift"></i>
                        <span>{props.t("Raffle Items")}</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.settings_view) && (
              <li>
                <Link to="/settings">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              </li>
            )}

            {(isAdmin || userPermissions.webinars_view || userPermissions.sponsers_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-globe"></i>
                  <span>{props.t("AgentBook")}</span>
                </Link>
                <ul className="sub-menu">
                  {(isAdmin || userPermissions.webinars_view) && (
                    <li>
                      <Link to="/agentbook/webinars">
                        <i className="bx bx-table"></i>
                        <span>{props.t("Webinars")}</span>
                      </Link>
                    </li>
                  )}
                  {(isAdmin || userPermissions.sponsers_view) && (
                    <li>
                      <Link to="/agentbook/sponsers">
                        <i className="fas fa-handshake"></i>
                        <span>{props.t("Sponsers")}</span>
                      </Link>
                    </li>
                  )}
                  {(isAdmin || userPermissions.sso_view) && (
                    <li>
                      <Link to="/agentbook/sso">
                        <i className="fas fa-user-lock"></i>
                        <span>{props.t("SSO")}</span>
                      </Link>
                    </li>
                  )}

                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.campaigns_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-rocket"></i>
                  <span>{props.t("Campaigns")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/campaigns/accounts">
                      {/* <i className="bx bx-table"></i> */}
                      <span>{props.t("Accounts List")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/campaigns">
                      {/* <i className="bx bx-table"></i> */}
                      <span>{props.t("List")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/campaigns/overview">
                      {/* <i className="fas fa-handshake"></i> */}
                      <span>{props.t("Overview")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.map_view) && (
              <li>
                <Link to="/map">
                  <i className="bx bx-map-alt"></i>
                  <span>{props.t("Map")}</span>
                </Link>
              </li>
            )}
            {(isAdmin || userPermissions.sendgrid_view) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-globe"></i>
                  <span>{props.t("Sendgrid")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sendgrid/suppressions">{props.t("Suppressions")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {(isAdmin || userPermissions.tutorials_view) && (
              <li>
                <Link to="/tutorials">
                  <i className="bx bx-food-menu"></i>
                  <span>{props.t("Tutorials")}</span>
                </Link>
              </li>
            )}

            {(isAdmin) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="fas fa-list-alt"></i>
                  <span>{props.t("Listing")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/listings/feeds">{props.t("Feeds")}</Link>
                  </li>
                  <li>
                    <Link to="/listings/indexes">{props.t("Indexes")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {(isAdmin) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-customize"></i>
                  <span>{props.t("Application")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/application/shared-media-library">{props.t("Shared Media Library")}</Link>
                  </li>
                </ul>
              </li>
            )}


          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
